@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scroll-margin-top : 64px;
    @media (min-width: 768px) {
        scroll-margin-top : 92px;
    }
    @media (min-width: 1280px) {
        scroll-margin-top : 120px;
    }
}

.start {
    opacity : 0
}
.end {
    opacity : 1;
    transition : opacity 0.5s;
}
